<template>
    <v-container fluid>
        <v-form @submit.prevent="getFitnessCalendars('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'fitness_calendars.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_fitness_calendar') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'fitness_calendar.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="day_week"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="day_week" :items="itemDayWeek"
                                              :error-messages="errors" :disabled="loadingDayWeek" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-calendar" :label="$t('day_week')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="group_lesson"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="group_lesson" :items="itemGroupLesson"
                                              :error-messages="errors" :disabled="loadingGroupLesson" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-multiple-outline" :label="$t('group_lesson')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="trainer"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="trainer" :items="itemTrainer"
                                              :error-messages="errors" :disabled="loadingTrainer" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-box-outline" :label="$t('trainer')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="fitness_calendarsItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalFitnessCalendars" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.day_week="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.day_week }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.time_from="{ item }">
                        {{  item.time_from ? $moment(item.time_from).format('HH:mm') : ''}}
                    </template>
                    <template v-slot:item.time_to="{ item }">
                        {{  item.time_to ? $moment(item.time_to).format('HH:mm') : ''}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="editFitnessCalendar(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteFitnessCalendar(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: "FitnessCalendars",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            sortBy: "id",
            sortDir: true,
            loading: false,
            loadingDayWeek: false,
            loadingGroupLesson: false,
            day_week: null,
            itemDayWeek: [],
            group_lesson: null,
            itemGroupLesson: [],
            loadingTrainer: false,
            trainer: null,
            itemTrainer: [],
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalFitnessCalendars: 0,
            fitness_calendar: null,
            fitness_calendarsItems: [],
            headers: [
                {
                    text: this.$t('day_week'),
                    align: "left",
                    sortable: false,
                    value: "day_week"
                },
                {
                    text: this.$t('group_lesson'),
                    align: "left",
                    sortable: false,
                    value: "group_lesson"
                },
                {
                    text: this.$t('time_from'),
                    align: "center",
                    sortable: false,
                    value: "time_from"
                },
                {
                    text: this.$t('time_to'),
                    align: "center",
                    sortable: false,
                    value: "time_to"
                },
                {
                    text: this.$t('trainer'),
                    align: "left",
                    sortable: false,
                    value: "trainer"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: false,
                    value: "active"
                },

                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            first_download: 1,
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getGroupLesson()
        await this.getDayWeek()
        await this.getTrainer()
        await this.getFitnessCalendars('search')
    },
    watch: {
        options: {
            handler() {
               // if(this.first_download === 0){
                    this.getFitnessCalendars()
             //   }
             //   this.first_download = 0;
            },
            deep: false
        },
    },
    methods: {
        editFitnessCalendar(item) {
            this.$router.push({
                name: 'fitness_calendar.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getFitnessCalendars(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.fitness_calendar) {
                params.fitness_calendar = this.fitness_calendar
            }
            if (this.day_week) {
                if (this.day_week.id) {
                    params.day_week =  this.day_week.id
                } else {
                    params.day_week =  this.day_week
                }
            }
            if (this.trainer) {
                if (this.trainer.id) {
                    params.trainer =  this.trainer.id
                } else {
                    params.trainer =  this.trainer
                }
            }
            if (this.group_lesson) {
                if (this.group_lesson.id) {
                    params.group_lesson =  this.group_lesson.id
                } else {
                    params.group_lesson =  this.group_lesson
                }
            }
            params.active = this.active

            await this.$http
                .get("admin/fitness_calendar", {
                    params: params,
                })
                .then(res => {
                    this.fitness_calendarsItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalFitnessCalendars = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.fitness_calendarsItems = []
                    this.totalFitnessCalendars = 0
                    this.$toastr.error(this.$t('failed_to_get_list_fitness_calendars'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteFitnessCalendar(item) {
            if (confirm(this.$t('delete_fitness_calendar'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/fitness_calendar/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('fitness_calendar_has_been_deleted'))
                        this.getFitnessCalendars()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('fitness_calendar_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getDayWeek() {
            this.loadingDayWeek = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`admin/day_week/`, {params: params})
                .then(res => {
                    this.itemDayWeek = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_day_weeks'));
                    this.itemDayWeek = []

                })
                .finally(end => {
                    this.loadingDayWeek = false
                });
        },
        async getTrainer() {
            this.loadingTrainer = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`admin/trainer/`, {params: params})
                .then(res => {
                    this.itemTrainer = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_trainers'));
                    this.itemTrainer = []

                })
                .finally(end => {
                    this.loadingTrainer = false
                });
        },
        async getGroupLesson() {
            this.loadingGroupLesson = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`admin/group_lesson/`, {params: params})
                .then(res => {
                    this.itemGroupLesson = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_group_lessons'));
                    this.itemGroupLesson = []

                })
                .finally(end => {
                    this.loadingGroupLesson = false
                });
        },

    }
}
</script>
